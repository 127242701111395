<template>
  <div class="login-container">
    <div class="login-bg">
      <div class="login-header">
        <img src="/static/logo.png" />
        <span class="item-line"></span>
        <h2 class="login-title">使馆派送系统</h2>
      </div>
    </div>
    <div class="login-form">
      <div class="login-form-hd">
        <img src="/static/logo_small.png" />
        <h2>系统登录</h2>
      </div>
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" auto-complete="on" label-position="left">
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" name="username" type="text" auto-complete="on" placeholder="Username">
            <span class="svg-container" slot="prefix">
              <svg-icon icon-class="username" />
            </span>
          </el-input>
        </el-form-item>
        <el-form-item prop="password" style="margin-bottom: 32px">
          <el-input
            :type="pwdType"
            v-model="loginForm.password"
            name="password"
            auto-complete="on"
            placeholder="Password"
            @keyup.enter.native="handleLogin">
            <span class="svg-container" slot="prefix">
              <svg-icon icon-class="password" />
            </span>
          </el-input>
        </el-form-item>
        <el-form-item style="border: 0 none;margin-bottom:0">
          <el-button :loading="loading" type="primary" style="width:100%;" @click.native.prevent="handleLogin">
            登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="login-ft">
      <p class="login-ft-cc">Copyright &copy; {{ year }} 使馆派送系统</p>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入正确的用户名'))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码不能小于6位'))
      } else {
        callback()
      }
    }
    return {
      year: (new Date()).getFullYear(),
      loginForm: {
        username: '',
        password: '',
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
        password: [{ required: true, trigger: 'blur', validator: validatePass }],
      },
      loading: false,
      pwdType: 'password',
      redirect: undefined,
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    showPwd() {
      if (this.pwdType === 'password') {
        this.pwdType = ''
      } else {
        this.pwdType = 'password'
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (!valid) return false;
        this.loading = true
        this.$store.dispatch('Login', this.loginForm)
        .then(() => {
          this.loading = false
          // this.$router.push({ path: '/dashboard' });
          window.location.href = '/dashboard'
        }).catch(err => {
          this.loading = false
          console.log(err)
        })
      })
    },
  }
}
</script>

<style lang="less" scoped>
.login {
  &-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  &-header {
    width: 960px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 10px 0;
    img {
      height: 40px;
    }
    .item-line {
      display: inline-block;
      width: 2px;
      height: 20px;
      background: rgba(255, 255, 255, .4);
      margin: 0 8px;
    }
  }
  &-title {
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    color: #fff;
  }
  &-bg {
    flex: 1;
    background-color: #E57D53;
    background-image: url(/static/login_bg.png);
    background-size: auto 100%;
    background-position: center 0;
    background-repeat: no-repeat;
  }
  &-ft {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    &-cc {
      padding: 22px 0;
      color: #B8B8B8;
      font-size: 14px;
      text-align: center;
    }
  }
  &-form {
    padding: 60px 40px;
    position: absolute;
    width: 706px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    background-image: url(/static/login_user.png);
    background-size: 300px 350px;
    background-repeat: no-repeat;
    padding-left: 360px;
    background-size: contain;
    background-position: 20px center;
    &-hd {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      img {
        height: 40px;
        margin-right: 10px;
      }
      h2 {
        font-size: 20px;
        color: #222;
        margin: 0;
      }
    }
    .svg-container {
      color: rgba(0, 0, 0, .45);
    }
    .show-pwd {
      cursor: pointer;
      user-select: none;
      color: rgba(0, 0, 0, .45);
    }
  }
}
</style>
